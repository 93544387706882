import {
  FullscreenOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
const {  Sider } = Layout;

const MenuData = [
  {
    key: "1",
    icon: <UserOutlined />,
    label: <Link to='/admin/home'>首页</Link>,
  },
  {
    key: "2",
    icon: <VideoCameraOutlined />,
    label: <Link to='/admin/userlist'>用户列表</Link>,
  },
  {
    key: "3",
    icon: <UploadOutlined />,
    label: "nav 3",
    children: [{ key: "3-1", label: "nav 3-1", icon: <UploadOutlined /> }],
  },
  {
    key: "4",
    icon: <UserOutlined />,
    label: "nav 1",
  },
  {
    key: "5",
    icon: <UserOutlined />,
    label: "nav 1",
  },
  {
    key: "6",
    icon: <UserOutlined />,
    label: "nav 1",
  },
  {
    key: "7",
    icon: <UserOutlined />,
    label: "nav 1",
  },
  {
    key: "8",
    icon: <UserOutlined />,
    label: "nav 1",
  },
];

const SideBar = ({ collapsed = false }) => {
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ overflow: "hidden" }}
    >
      <div
        style={{
          height: "32px",
          margin: "16px",
          background: "rgba(255, 255, 255, 0.3)",
          color: "white",
          textAlign: "center",
          lineHeight: "32px",
        }}
      >
        {!collapsed ? "测试标题" : <FullscreenOutlined />}
      </div>
      <Scrollbars>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={MenuData}
        />
      </Scrollbars>
    </Sider>
  );
};
export default SideBar;
