import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import App from "../pages/App";
import Login from "../pages/Login";
import Error from "../pages/Error/index";
const token = localStorage.getItem("token");
export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to= "/login"></Navigate>}
      ></Route>
      <Route path="/login" element={<Login></Login>}></Route>
      <Route
        path="/admin/*"
        element={token ? <App></App> : <Navigate to={"/login"}></Navigate>}
      ></Route>
      <Route path="*" element={<Error></Error>}></Route>
    </Routes>
  );
}
