import Mock from 'mockjs'
// import JWT from './jwt/jwt'
import userList from './json/userList.json'
Mock.setup({
    timeout: "500-1000"
})
Mock.mock('/user/list', 'get', userList)
Mock.mock('/user/login', 'post', req => {

    const {username,password}=JSON.parse(req.body)
    let currentList=Mock.mock(userList)
    let users=[...currentList.data,currentList.superAdmin]
    let isAuth,token;
    console.log('a',username,password);
    for(let user of users){
        if(user.username===username && user.password===Number(password)){
            isAuth=user
            // token=JWT.generate(username,'1d')
            token='123123'
            break
        }
    }
    return isAuth ? Mock.mock({
        code: 200,
        msg: 'success',
        data: isAuth,
        token:token    
    }):Mock.mock({
        code: 404,
        msg: 'error',
        data: '账号或者密码错误',  
    })
})

export default Mock