import { createSlice } from "@reduxjs/toolkit";

const LoginSlice=createSlice({
    name:'login',
    initialState:{
        user:{username:'游客'}
    },
    reducers:{
        getUser:(state,action)=>{
            return {user:{...state.user,...action.payload,password:''}}
        }
    }
})

export const {getUser} =LoginSlice.actions
export default LoginSlice.reducer