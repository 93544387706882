import { Layout } from "antd";
import React, { useState, Suspense } from "react";
import MyHeader from "./components/Header/MyHeader";
import SideBar from "./components/SideBar/index";

import { Scrollbars } from "react-custom-scrollbars";
import {AdminRoute} from "../routes/AdminRoutes";
// import RouterBeforeEach from "../routes/RouterBefore";
const { Content } = Layout;

// axios.post('/user/login',{username:'qq',password:'123123'}).then(res=>{
//   console.log(res)
// })

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ height: "100%" }}>
      <SideBar collapsed={collapsed}></SideBar>

      <Layout style={{ background: "#fff" }}>
        <MyHeader handleToggle={handleToggle} collapsed={collapsed}></MyHeader>{" "}
        <Scrollbars>
          <Content
            style={{
              margin: "24px 16px",
              padding: 10,
              minHeight: 280,
              background: "#fff",
            }}
          >
            <Suspense>
              <AdminRoute></AdminRoute>
            </Suspense>
          </Content>
        </Scrollbars>
      </Layout>
    </Layout>
  );
};

export default App;
