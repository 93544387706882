import { lazy } from "react";

export const routes = [
  {
    path: "home/*",
    component: lazy(() => import("../pages/Admin/Home/index")),
    children: [
      {
        path: "",
        component: lazy(() => import("../pages/Admin/Home/index")),
      },
    ],
  },
  {
    path: "userlist",
    component: lazy(() => import("../pages/Admin/UserList/UserList")),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/Error/index")),
  },
];



