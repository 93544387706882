import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './mock/index'

import './utils/https'
import './index.scss';
import 'antd/dist/antd.css';
import 'nprogress/nprogress.css'


import reportWebVitals from './reportWebVitals';
import Head from './pages/Head/index'
import { Provider } from 'react-redux'
import store from './pages/store/store';
import AppRoutes from './routes/AppRoutes';
// import { Router } from './routes/GuardRoutes';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Head></Head>
      <AppRoutes />
    </Provider>
  </BrowserRouter>

);
// 自检查错
reportWebVitals();
