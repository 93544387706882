import axios from 'axios'
import resData from '../mock'

export const login=(formData)=>{
    console.log('logindata-----------------')
    return axios({
        url:'/user/login',
        method:'POST',
        headers:{'Content-Type':'application/x-www-form-urlencoded'},
        data:formData,//formData
        responseType:'json'
    }).then(res=>{
        console.log(res)
        return res
    }).catch(err=>{
        console.log(err)
    })
}